export const routes = [
  {
    route: "inicio",
    naming: "Inicio",
  },
  {
    route: "requisitos",
    naming: "Requisitos",
  },
  {
    route: "nosotros",
    naming: "Nosotros",
  },
  {
    route: "faq",
    naming: "FAQ",
  },
  {
    route: "beneficios",
    naming: "Beneficios",
  },
];
