import React from "react";
import styled from "styled-components";
import Image from "../../icon/botdecoration.png";
import { InstagramIcon } from "../../icon/InstagramIcon";
import { LinkedinIcon } from "../../icon/LinkedinIcon";
import Logo from "../../icon/logo3.png";
import { FacebookIcon } from "./../../icon/FacebookIcon";
import { PhoneIcon } from "./../../icon/PhoneIcon";
import { EmailIcon } from "./../../icon/EmailIcon";
import { LocationIcon } from "./../../icon/LocationIcon";
import { WhatsappIcon } from "./../../icon/WhatsappIcon";

const FooterContainer = styled.div`
  width: 100%;
  .decoration-container {
    width: 100%;
    margin-bottom: -10px;
    img {
      width: 100%;
    }
  }
  .content-container {
    background-color: #f9f9f9;
    padding: 0 100px 40px 100px;
    display: flex;
    flex-direction: column;
    .social {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      padding: 15px;
      margin: 10px 10px;
      display: flex;
      align-items: center !important;
      cursor: pointer;
      background-color: #9571fb25;
      &:hover {
        background-color: #9571fb30;
      }
      svg {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      path {
        fill: #9571fb;
      }
    }
    .top-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      #foot {
        margin-top: 30px;
        font-size: 14px;
        color: #9a9ea6;
        max-width: 768px;
        text-align: center;
        margin-bottom: 10px;
      }
      #terms {
        font-size: 14px;
        color: #9a9ea6;
        max-width: 768px;
        text-align: center;
        margin-bottom: 10px;
      }
      #monthlypayment {
        margin-top: 10px;
        font-size: 14px;
        color: #9a9ea6;
        max-width: 768px;
        text-align: center;
        margin-bottom: 5px;
      }
      #cft {
        margin-top: 0;
        font-size: 70px;
        color: #9a9ea6;
        max-width: 768px;
        text-align: center;
        margin-bottom: 0;
      }
      .social-container {
        display: flex;
        flex-direction: row;
      }
    }
    .info-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 30px;
      .item {
        max-width: 276px;
        display: flex;
        flex-direction: row;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          h3 {
            font-size: 12px;
            margin: 0 0 2px 0;
            color: #9a9ea6;
            font-weight: 400;
          }
          p {
            font-size: 16px;
            margin: 0;
            font-weight: 700;
          }
        }
      }
    }
    .legal-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 60px;
      color: #9a9ea6;
      div {
        display: flex;
        flex-direction: row;
        a {
          margin: 0 50px 0 0;
          cursor: pointer;
          &:hover {
            text-decoration-line: underline;
          }
        }
      }
      p {
        margin: 0;
      }
    }
  }
  @media (max-width: 600px) {
    .decoration-container {
      display: none;
    }
    .content-container {
      padding: 50px 15px 40px;
      #description {
        width: 80%;
      }
      .legal-container {
        flex-direction: column;
        align-items: center;
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 40px;
        }
        p,
        a {
          font-size: 10px;
          margin: 0px !important;
        }
      }
    }
    .info-container {
      display: none !important;
    }
  }
`;

export const Footer = ({ cft, tna }) => {
  return (
    <FooterContainer>
      <div className="decoration-container">
        <img src={Image} />
      </div>
      <div className="content-container">
        <div className="top-container">
          <img src={Logo} width={"200px"} />
          <p id="foot">
            (*) Sujeto a aprobación crediticia. Todos los préstamos son a tasa fija, en pesos y otorgados bajo el
            sistema de amortización francés con cuotas mensuales y consecutivas. En todos los casos, la Tasa Nominal
            Anual (TNA) y el Costo Financiero Total (CFT) aplicables serán informados antes de la aceptación de la
            oferta de préstamo por parte del solicitante. La adhesión al servicio Club Pressto tiene un costo de $5.990
            final por mes. El otorgamiento de préstamos se encuentra sujeto a evaluación crediticia, por lo que la
            adhesión al servicio (membresía) no garantiza la aprobación ni otorgación del mismo. La aprobación
            definitiva del préstamo quedará supeditada al cumplimiento de las condiciones exigidas por PRESTACIÓN DE
            SERVICIOS INTEGRALES S.R.L. La baja del servicio puede ser realizada en cualquier momento, enviando un
            correo electrónico a baja@clubpressto.com.ar. Al adherirse al servicio, usted acepta los Términos y
            Condiciones de Club Pressto.
          </p>
          <a id="terms" href="https://terminosycondiciones.clubpressto.com.ar">
            <p>Ver Términos y Condiciones</p>
          </a>
          <p id="foot">Para la cantidad de cuotas seleccionada (IVA incluído): TNA: {tna}</p>
          <p id="cft">CFTEA: {cft}</p>
        </div>
        <div className="info-container">
          {/* <div className='item'>
                        <div className='social'>
                            <PhoneIcon/>
                        </div>
                        <div>
                            <h3>Teléfono</h3>
                            <p>11 1234-1234</p>
                        </div>
                    </div> */}
          <div className="item">
            <div className="social">
              <EmailIcon />
            </div>
            <div>
              <h3>Email</h3>
              <p>contacto@clubpressto.com.ar</p>
            </div>
          </div>

          <div className="item">
            <div className="social">
              <a href="https://wa.me/5491161309671">
                <WhatsappIcon />
              </a>
            </div>
            <div>
              <h3>WhatsApp</h3>
              <p>+54 911 6130-9671</p>
            </div>
          </div>
        </div>
        <div className="legal-container">
          <div>
            {/* <a>Política de privacidad</a>
                        <a>Términos y condiciones</a> */}
          </div>
          <p>
            PRESTACIÓN DE SERVICIOS INTEGRALES S.R.L. Todos los derechos reservados. Nahuel Huapi 5863, CABA, Argentina.
          </p>
        </div>
      </div>
    </FooterContainer>
  );
};
