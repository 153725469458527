export const Decoration4 = () => {
    return(
        <svg width="180" height="179" viewBox="0 0 180 179" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M90 62.2351C74.8579 62.2351 62.5828 74.442 62.5828 89.5C62.5828 104.558 74.8579 116.765 90 116.765C105.142 116.765 117.417 104.558 117.417 89.5C117.417 74.442 105.142 62.2351 90 62.2351ZM63.7748 89.5C63.7748 75.0967 75.5162 63.4205 90 63.4205C104.484 63.4205 116.225 75.0967 116.225 89.5C116.225 103.903 104.484 115.579 90 115.579C75.5162 115.579 63.7748 103.903 63.7748 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 57.7897C72.3891 57.7897 58.1126 71.9869 58.1126 89.5C58.1126 107.013 72.3891 121.21 90 121.21C107.611 121.21 121.887 107.013 121.887 89.5C121.887 71.9869 107.611 57.7897 90 57.7897ZM59.3046 89.5C59.3046 72.6416 73.0474 58.9752 90 58.9752C106.953 58.9752 120.695 72.6416 120.695 89.5C120.695 106.358 106.953 120.025 90 120.025C73.0474 120.025 59.3046 106.358 59.3046 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M53.6424 89.5C53.6424 69.5318 69.9202 53.3444 90 53.3444C110.08 53.3444 126.358 69.5318 126.358 89.5C126.358 109.468 110.08 125.656 90 125.656C69.9202 125.656 53.6424 109.468 53.6424 89.5ZM90 54.5298C70.5786 54.5298 54.8344 70.1865 54.8344 89.5C54.8344 108.814 70.5786 124.47 90 124.47C109.421 124.47 125.166 108.814 125.166 89.5C125.166 70.1865 109.421 54.5298 90 54.5298Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M49.1722 89.5C49.1722 67.0767 67.4514 48.899 90 48.899C112.549 48.899 130.828 67.0767 130.828 89.5C130.828 111.923 112.549 130.101 90 130.101C67.4514 130.101 49.1722 111.923 49.1722 89.5ZM90 50.0844C68.1098 50.0844 50.3642 67.7314 50.3642 89.5C50.3642 111.269 68.1098 128.916 90 128.916C111.89 128.916 129.636 111.269 129.636 89.5C129.636 67.7314 111.89 50.0844 90 50.0844Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M44.702 89.5C44.702 64.6216 64.9826 44.4536 90 44.4536C115.017 44.4536 135.298 64.6216 135.298 89.5C135.298 114.378 115.017 134.546 90 134.546C64.9826 134.546 44.702 114.378 44.702 89.5ZM90 45.6391C65.641 45.6391 45.894 65.2763 45.894 89.5C45.894 113.724 65.641 133.361 90 133.361C114.359 133.361 134.106 113.724 134.106 89.5C134.106 65.2763 114.359 45.6391 90 45.6391Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 40.0083C62.5138 40.0083 40.2318 62.1665 40.2318 89.5C40.2318 116.834 62.5138 138.992 90 138.992C117.486 138.992 139.768 116.834 139.768 89.5C139.768 62.1665 117.486 40.0083 90 40.0083ZM41.4238 89.5C41.4238 62.8212 63.1721 41.1937 90 41.1937C116.828 41.1937 138.576 62.8212 138.576 89.5C138.576 116.179 116.828 137.806 90 137.806C63.1721 137.806 41.4238 116.179 41.4238 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M35.7616 89.5C35.7616 59.7114 60.045 35.5629 90 35.5629C119.955 35.5629 144.238 59.7114 144.238 89.5C144.238 119.289 119.955 143.437 90 143.437C60.045 143.437 35.7616 119.289 35.7616 89.5ZM90 36.7483C60.7033 36.7483 36.9536 60.3661 36.9536 89.5C36.9536 118.634 60.7033 142.252 90 142.252C119.297 142.252 143.046 118.634 143.046 89.5C143.046 60.3661 119.297 36.7483 90 36.7483Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 31.1175C57.5761 31.1175 31.2914 57.2563 31.2914 89.5C31.2914 121.744 57.5761 147.882 90 147.882C122.424 147.882 148.709 121.744 148.709 89.5C148.709 57.2563 122.424 31.1175 90 31.1175ZM32.4834 89.5C32.4834 57.911 58.2345 32.303 90 32.303C121.766 32.303 147.517 57.911 147.517 89.5C147.517 121.089 121.766 146.697 90 146.697C58.2345 146.697 32.4834 121.089 32.4834 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.8212 89.5C26.8212 54.8012 55.1073 26.6722 90 26.6722C124.893 26.6722 153.179 54.8012 153.179 89.5C153.179 124.199 124.893 152.328 90 152.328C55.1073 152.328 26.8212 124.199 26.8212 89.5ZM90 27.8576C55.7657 27.8576 28.0132 55.4558 28.0132 89.5C28.0132 123.544 55.7657 151.142 90 151.142C124.234 151.142 151.987 123.544 151.987 89.5C151.987 55.4558 124.234 27.8576 90 27.8576Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 22.2268C52.6385 22.2268 22.351 52.346 22.351 89.5C22.351 126.654 52.6385 156.773 90 156.773C127.362 156.773 157.649 126.654 157.649 89.5C157.649 52.346 127.362 22.2268 90 22.2268ZM23.543 89.5C23.543 53.0007 53.2968 23.4123 90 23.4123C126.703 23.4123 156.457 53.0007 156.457 89.5C156.457 125.999 126.703 155.588 90 155.588C53.2968 155.588 23.543 125.999 23.543 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.8808 89.5C17.8808 49.8909 50.1697 17.7815 90 17.7815C129.83 17.7815 162.119 49.8909 162.119 89.5C162.119 129.109 129.83 161.219 90 161.219C50.1697 161.219 17.8808 129.109 17.8808 89.5ZM90 18.9669C50.828 18.9669 19.0728 50.5456 19.0728 89.5C19.0728 128.454 50.828 160.033 90 160.033C129.172 160.033 160.927 128.454 160.927 89.5C160.927 50.5456 129.172 18.9669 90 18.9669Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 13.3361C47.7008 13.3361 13.4106 47.4358 13.4106 89.5C13.4106 131.564 47.7008 165.664 90 165.664C132.299 165.664 166.589 131.564 166.589 89.5C166.589 47.4358 132.299 13.3361 90 13.3361ZM14.6026 89.5C14.6026 48.0905 48.3592 14.5215 90 14.5215C131.641 14.5215 165.397 48.0905 165.397 89.5C165.397 130.909 131.641 164.478 90 164.478C48.3592 164.478 14.6026 130.909 14.6026 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M8.9404 89.5C8.9404 44.9807 45.232 8.89073 90 8.89073C134.768 8.89073 171.06 44.9807 171.06 89.5C171.06 134.019 134.768 170.109 90 170.109C45.232 170.109 8.9404 134.019 8.9404 89.5ZM90 10.0762C45.8904 10.0762 10.1325 45.6354 10.1325 89.5C10.1325 133.365 45.8904 168.924 90 168.924C134.11 168.924 169.868 133.365 169.868 89.5C169.868 45.6354 134.11 10.0762 90 10.0762Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90 4.44536C42.7632 4.44536 4.4702 42.5256 4.4702 89.5C4.4702 136.474 42.7632 174.555 90 174.555C137.237 174.555 175.53 136.474 175.53 89.5C175.53 42.5256 137.237 4.44536 90 4.44536ZM5.66225 89.5C5.66225 43.1803 43.4215 5.63079 90 5.63079C136.578 5.63079 174.338 43.1803 174.338 89.5C174.338 135.82 136.578 173.369 90 173.369C43.4215 173.369 5.66225 135.82 5.66225 89.5Z" fill="#F0EAFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M0 89.5C0 40.0705 40.2944 0 90 0C139.706 0 180 40.0705 180 89.5C180 138.929 139.706 179 90 179C40.2944 179 0 138.929 0 89.5ZM90 1.18543C40.9527 1.18543 1.19205 40.7252 1.19205 89.5C1.19205 138.275 40.9527 177.815 90 177.815C139.047 177.815 178.808 138.275 178.808 89.5C178.808 40.7252 139.047 1.18543 90 1.18543Z" fill="#F0EAFF"/>
</svg>
    )
}