export const Decoration3 = () => {
    return(
        <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M289.287 69.7703C290.118 71.2093 290.927 72.6623 291.714 74.129C290.285 73.1942 289.33 71.5951 289.287 69.7703Z" fill="#F0EAFF"/>
<path d="M259.954 32.7852C262.451 35.1279 264.872 37.5494 267.215 40.0457C266.309 41.72 264.537 42.8571 262.5 42.8571C259.541 42.8571 257.143 40.4587 257.143 37.5C257.143 35.4628 258.28 33.6911 259.954 32.7852Z" fill="#F0EAFF"/>
<path d="M225.871 8.28625C227.338 9.07314 228.791 9.88214 230.23 10.7128C228.405 10.6702 226.806 9.71517 225.871 8.28625Z" fill="#F0EAFF"/>
<path d="M69.7703 10.7128C71.2093 9.88214 72.6623 9.07314 74.129 8.28625C73.1942 9.71517 71.5951 10.6702 69.7703 10.7128Z" fill="#F0EAFF"/>
<path d="M32.7852 40.0457C35.1279 37.5494 37.5494 35.1279 40.0457 32.7852C41.72 33.6911 42.8571 35.4628 42.8571 37.5C42.8571 40.4587 40.4587 42.8571 37.5 42.8571C35.4628 42.8571 33.6911 41.72 32.7852 40.0457Z" fill="#F0EAFF"/>
<path d="M8.28625 74.129C9.07314 72.6623 9.88214 71.2093 10.7128 69.7703C10.6702 71.5951 9.71517 73.1942 8.28625 74.129Z" fill="#F0EAFF"/>
<path d="M291.714 225.871C290.927 227.338 290.118 228.791 289.287 230.23C289.33 228.405 290.285 226.806 291.714 225.871Z" fill="#F0EAFF"/>
<path d="M267.215 259.954C266.309 258.28 264.537 257.143 262.5 257.143C259.541 257.143 257.143 259.541 257.143 262.5C257.143 264.537 258.28 266.309 259.954 267.215C262.451 264.872 264.872 262.451 267.215 259.954Z" fill="#F0EAFF"/>
<path d="M230.23 289.287C228.405 289.33 226.806 290.285 225.871 291.714C227.338 290.927 228.791 290.118 230.23 289.287Z" fill="#F0EAFF"/>
<path d="M74.129 291.714C73.1942 290.285 71.5951 289.33 69.7703 289.287C71.2093 290.118 72.6623 290.927 74.129 291.714Z" fill="#F0EAFF"/>
<path d="M40.0457 267.215C41.72 266.309 42.8571 264.537 42.8571 262.5C42.8571 259.541 40.4587 257.143 37.5 257.143C35.4628 257.143 33.6911 258.28 32.7852 259.954C35.1279 262.451 37.5494 264.872 40.0457 267.215Z" fill="#F0EAFF"/>
<path d="M10.7128 230.23C10.6702 228.405 9.71517 226.806 8.28625 225.871C9.07314 227.338 9.88214 228.791 10.7128 230.23Z" fill="#F0EAFF"/>
<path d="M107.143 5.35714C107.143 8.31581 104.744 10.7143 101.786 10.7143C98.827 10.7143 96.4286 8.31581 96.4286 5.35714C96.4286 2.39847 98.827 0 101.786 0C104.744 0 107.143 2.39847 107.143 5.35714Z" fill="#F0EAFF"/>
<path d="M139.286 5.35714C139.286 8.31581 136.887 10.7143 133.929 10.7143C130.97 10.7143 128.571 8.31581 128.571 5.35714C128.571 2.39847 130.97 0 133.929 0C136.887 0 139.286 2.39847 139.286 5.35714Z" fill="#F0EAFF"/>
<path d="M171.429 5.35714C171.429 8.31581 169.03 10.7143 166.071 10.7143C163.113 10.7143 160.714 8.31581 160.714 5.35714C160.714 2.39847 163.113 0 166.071 0C169.03 0 171.429 2.39847 171.429 5.35714Z" fill="#F0EAFF"/>
<path d="M203.571 5.35714C203.571 8.31581 201.173 10.7143 198.214 10.7143C195.256 10.7143 192.857 8.31581 192.857 5.35714C192.857 2.39847 195.256 0 198.214 0C201.173 0 203.571 2.39847 203.571 5.35714Z" fill="#F0EAFF"/>
<path d="M75 37.5C75 40.4587 72.6015 42.8571 69.6429 42.8571C66.6842 42.8571 64.2857 40.4587 64.2857 37.5C64.2857 34.5413 66.6842 32.1429 69.6429 32.1429C72.6015 32.1429 75 34.5413 75 37.5Z" fill="#F0EAFF"/>
<path d="M107.143 37.5C107.143 40.4587 104.744 42.8571 101.786 42.8571C98.827 42.8571 96.4286 40.4587 96.4286 37.5C96.4286 34.5413 98.827 32.1429 101.786 32.1429C104.744 32.1429 107.143 34.5413 107.143 37.5Z" fill="#F0EAFF"/>
<path d="M139.286 37.5C139.286 40.4587 136.887 42.8571 133.929 42.8571C130.97 42.8571 128.571 40.4587 128.571 37.5C128.571 34.5413 130.97 32.1429 133.929 32.1429C136.887 32.1429 139.286 34.5413 139.286 37.5Z" fill="#F0EAFF"/>
<path d="M171.429 37.5C171.429 40.4587 169.03 42.8571 166.071 42.8571C163.113 42.8571 160.714 40.4587 160.714 37.5C160.714 34.5413 163.113 32.1429 166.071 32.1429C169.03 32.1429 171.429 34.5413 171.429 37.5Z" fill="#F0EAFF"/>
<path d="M203.571 37.5C203.571 40.4587 201.173 42.8571 198.214 42.8571C195.256 42.8571 192.857 40.4587 192.857 37.5C192.857 34.5413 195.256 32.1429 198.214 32.1429C201.173 32.1429 203.571 34.5413 203.571 37.5Z" fill="#F0EAFF"/>
<path d="M235.714 37.5C235.714 40.4587 233.316 42.8571 230.357 42.8571C227.398 42.8571 225 40.4587 225 37.5C225 34.5413 227.398 32.1429 230.357 32.1429C233.316 32.1429 235.714 34.5413 235.714 37.5Z" fill="#F0EAFF"/>
<path d="M42.8571 69.6429C42.8571 72.6015 40.4587 75 37.5 75C34.5413 75 32.1429 72.6015 32.1429 69.6429C32.1429 66.6842 34.5413 64.2857 37.5 64.2857C40.4587 64.2857 42.8571 66.6842 42.8571 69.6429Z" fill="#F0EAFF"/>
<path d="M75 69.6429C75 72.6015 72.6015 75 69.6429 75C66.6842 75 64.2857 72.6015 64.2857 69.6429C64.2857 66.6842 66.6842 64.2857 69.6429 64.2857C72.6015 64.2857 75 66.6842 75 69.6429Z" fill="#F0EAFF"/>
<path d="M107.143 69.6429C107.143 72.6015 104.744 75 101.786 75C98.827 75 96.4286 72.6015 96.4286 69.6429C96.4286 66.6842 98.827 64.2857 101.786 64.2857C104.744 64.2857 107.143 66.6842 107.143 69.6429Z" fill="#F0EAFF"/>
<path d="M139.286 69.6429C139.286 72.6015 136.887 75 133.929 75C130.97 75 128.571 72.6015 128.571 69.6429C128.571 66.6842 130.97 64.2857 133.929 64.2857C136.887 64.2857 139.286 66.6842 139.286 69.6429Z" fill="#F0EAFF"/>
<path d="M171.429 69.6429C171.429 72.6015 169.03 75 166.071 75C163.113 75 160.714 72.6015 160.714 69.6429C160.714 66.6842 163.113 64.2857 166.071 64.2857C169.03 64.2857 171.429 66.6842 171.429 69.6429Z" fill="#F0EAFF"/>
<path d="M203.571 69.6429C203.571 72.6015 201.173 75 198.214 75C195.256 75 192.857 72.6015 192.857 69.6429C192.857 66.6842 195.256 64.2857 198.214 64.2857C201.173 64.2857 203.571 66.6842 203.571 69.6429Z" fill="#F0EAFF"/>
<path d="M235.714 69.6429C235.714 72.6015 233.316 75 230.357 75C227.398 75 225 72.6015 225 69.6429C225 66.6842 227.398 64.2857 230.357 64.2857C233.316 64.2857 235.714 66.6842 235.714 69.6429Z" fill="#F0EAFF"/>
<path d="M267.857 69.6429C267.857 72.6015 265.459 75 262.5 75C259.541 75 257.143 72.6015 257.143 69.6429C257.143 66.6842 259.541 64.2857 262.5 64.2857C265.459 64.2857 267.857 66.6842 267.857 69.6429Z" fill="#F0EAFF"/>
<path d="M10.7143 101.786C10.7143 104.744 8.31581 107.143 5.35714 107.143C2.39847 107.143 0 104.744 0 101.786C0 98.827 2.39847 96.4286 5.35714 96.4286C8.31581 96.4286 10.7143 98.827 10.7143 101.786Z" fill="#F0EAFF"/>
<path d="M42.8571 101.786C42.8571 104.744 40.4587 107.143 37.5 107.143C34.5413 107.143 32.1429 104.744 32.1429 101.786C32.1429 98.827 34.5413 96.4286 37.5 96.4286C40.4587 96.4286 42.8571 98.827 42.8571 101.786Z" fill="#F0EAFF"/>
<path d="M75 101.786C75 104.744 72.6015 107.143 69.6429 107.143C66.6842 107.143 64.2857 104.744 64.2857 101.786C64.2857 98.827 66.6842 96.4286 69.6429 96.4286C72.6015 96.4286 75 98.827 75 101.786Z" fill="#F0EAFF"/>
<path d="M107.143 101.786C107.143 104.744 104.744 107.143 101.786 107.143C98.827 107.143 96.4286 104.744 96.4286 101.786C96.4286 98.827 98.827 96.4286 101.786 96.4286C104.744 96.4286 107.143 98.827 107.143 101.786Z" fill="#F0EAFF"/>
<path d="M139.286 101.786C139.286 104.744 136.887 107.143 133.929 107.143C130.97 107.143 128.571 104.744 128.571 101.786C128.571 98.827 130.97 96.4286 133.929 96.4286C136.887 96.4286 139.286 98.827 139.286 101.786Z" fill="#F0EAFF"/>
<path d="M171.429 101.786C171.429 104.744 169.03 107.143 166.071 107.143C163.113 107.143 160.714 104.744 160.714 101.786C160.714 98.827 163.113 96.4286 166.071 96.4286C169.03 96.4286 171.429 98.827 171.429 101.786Z" fill="#F0EAFF"/>
<path d="M203.571 101.786C203.571 104.744 201.173 107.143 198.214 107.143C195.256 107.143 192.857 104.744 192.857 101.786C192.857 98.827 195.256 96.4286 198.214 96.4286C201.173 96.4286 203.571 98.827 203.571 101.786Z" fill="#F0EAFF"/>
<path d="M235.714 101.786C235.714 104.744 233.316 107.143 230.357 107.143C227.398 107.143 225 104.744 225 101.786C225 98.827 227.398 96.4286 230.357 96.4286C233.316 96.4286 235.714 98.827 235.714 101.786Z" fill="#F0EAFF"/>
<path d="M267.857 101.786C267.857 104.744 265.459 107.143 262.5 107.143C259.541 107.143 257.143 104.744 257.143 101.786C257.143 98.827 259.541 96.4286 262.5 96.4286C265.459 96.4286 267.857 98.827 267.857 101.786Z" fill="#F0EAFF"/>
<path d="M300 101.786C300 104.744 297.602 107.143 294.643 107.143C291.684 107.143 289.286 104.744 289.286 101.786C289.286 98.827 291.684 96.4286 294.643 96.4286C297.602 96.4286 300 98.827 300 101.786Z" fill="#F0EAFF"/>
<path d="M10.7143 133.929C10.7143 136.887 8.31581 139.286 5.35714 139.286C2.39847 139.286 0 136.887 0 133.929C0 130.97 2.39847 128.571 5.35714 128.571C8.31581 128.571 10.7143 130.97 10.7143 133.929Z" fill="#F0EAFF"/>
<path d="M42.8571 133.929C42.8571 136.887 40.4587 139.286 37.5 139.286C34.5413 139.286 32.1429 136.887 32.1429 133.929C32.1429 130.97 34.5413 128.571 37.5 128.571C40.4587 128.571 42.8571 130.97 42.8571 133.929Z" fill="#F0EAFF"/>
<path d="M75 133.929C75 136.887 72.6015 139.286 69.6429 139.286C66.6842 139.286 64.2857 136.887 64.2857 133.929C64.2857 130.97 66.6842 128.571 69.6429 128.571C72.6015 128.571 75 130.97 75 133.929Z" fill="#F0EAFF"/>
<path d="M107.143 133.929C107.143 136.887 104.744 139.286 101.786 139.286C98.827 139.286 96.4286 136.887 96.4286 133.929C96.4286 130.97 98.827 128.571 101.786 128.571C104.744 128.571 107.143 130.97 107.143 133.929Z" fill="#F0EAFF"/>
<path d="M139.286 133.929C139.286 136.887 136.887 139.286 133.929 139.286C130.97 139.286 128.571 136.887 128.571 133.929C128.571 130.97 130.97 128.571 133.929 128.571C136.887 128.571 139.286 130.97 139.286 133.929Z" fill="#F0EAFF"/>
<path d="M171.429 133.929C171.429 136.887 169.03 139.286 166.071 139.286C163.113 139.286 160.714 136.887 160.714 133.929C160.714 130.97 163.113 128.571 166.071 128.571C169.03 128.571 171.429 130.97 171.429 133.929Z" fill="#F0EAFF"/>
<path d="M203.571 133.929C203.571 136.887 201.173 139.286 198.214 139.286C195.256 139.286 192.857 136.887 192.857 133.929C192.857 130.97 195.256 128.571 198.214 128.571C201.173 128.571 203.571 130.97 203.571 133.929Z" fill="#F0EAFF"/>
<path d="M235.714 133.929C235.714 136.887 233.316 139.286 230.357 139.286C227.398 139.286 225 136.887 225 133.929C225 130.97 227.398 128.571 230.357 128.571C233.316 128.571 235.714 130.97 235.714 133.929Z" fill="#F0EAFF"/>
<path d="M267.857 133.929C267.857 136.887 265.459 139.286 262.5 139.286C259.541 139.286 257.143 136.887 257.143 133.929C257.143 130.97 259.541 128.571 262.5 128.571C265.459 128.571 267.857 130.97 267.857 133.929Z" fill="#F0EAFF"/>
<path d="M300 133.929C300 136.887 297.602 139.286 294.643 139.286C291.684 139.286 289.286 136.887 289.286 133.929C289.286 130.97 291.684 128.571 294.643 128.571C297.602 128.571 300 130.97 300 133.929Z" fill="#F0EAFF"/>
<path d="M10.7143 166.071C10.7143 169.03 8.31581 171.429 5.35714 171.429C2.39847 171.429 0 169.03 0 166.071C0 163.113 2.39847 160.714 5.35714 160.714C8.31581 160.714 10.7143 163.113 10.7143 166.071Z" fill="#F0EAFF"/>
<path d="M42.8571 166.071C42.8571 169.03 40.4587 171.429 37.5 171.429C34.5413 171.429 32.1429 169.03 32.1429 166.071C32.1429 163.113 34.5413 160.714 37.5 160.714C40.4587 160.714 42.8571 163.113 42.8571 166.071Z" fill="#F0EAFF"/>
<path d="M75 166.071C75 169.03 72.6015 171.429 69.6429 171.429C66.6842 171.429 64.2857 169.03 64.2857 166.071C64.2857 163.113 66.6842 160.714 69.6429 160.714C72.6015 160.714 75 163.113 75 166.071Z" fill="#F0EAFF"/>
<path d="M107.143 166.071C107.143 169.03 104.744 171.429 101.786 171.429C98.827 171.429 96.4286 169.03 96.4286 166.071C96.4286 163.113 98.827 160.714 101.786 160.714C104.744 160.714 107.143 163.113 107.143 166.071Z" fill="#F0EAFF"/>
<path d="M139.286 166.071C139.286 169.03 136.887 171.429 133.929 171.429C130.97 171.429 128.571 169.03 128.571 166.071C128.571 163.113 130.97 160.714 133.929 160.714C136.887 160.714 139.286 163.113 139.286 166.071Z" fill="#F0EAFF"/>
<path d="M171.429 166.071C171.429 169.03 169.03 171.429 166.071 171.429C163.113 171.429 160.714 169.03 160.714 166.071C160.714 163.113 163.113 160.714 166.071 160.714C169.03 160.714 171.429 163.113 171.429 166.071Z" fill="#F0EAFF"/>
<path d="M203.571 166.071C203.571 169.03 201.173 171.429 198.214 171.429C195.256 171.429 192.857 169.03 192.857 166.071C192.857 163.113 195.256 160.714 198.214 160.714C201.173 160.714 203.571 163.113 203.571 166.071Z" fill="#F0EAFF"/>
<path d="M235.714 166.071C235.714 169.03 233.316 171.429 230.357 171.429C227.398 171.429 225 169.03 225 166.071C225 163.113 227.398 160.714 230.357 160.714C233.316 160.714 235.714 163.113 235.714 166.071Z" fill="#F0EAFF"/>
<path d="M267.857 166.071C267.857 169.03 265.459 171.429 262.5 171.429C259.541 171.429 257.143 169.03 257.143 166.071C257.143 163.113 259.541 160.714 262.5 160.714C265.459 160.714 267.857 163.113 267.857 166.071Z" fill="#F0EAFF"/>
<path d="M300 166.071C300 169.03 297.602 171.429 294.643 171.429C291.684 171.429 289.286 169.03 289.286 166.071C289.286 163.113 291.684 160.714 294.643 160.714C297.602 160.714 300 163.113 300 166.071Z" fill="#F0EAFF"/>
<path d="M10.7143 198.214C10.7143 201.173 8.31581 203.571 5.35714 203.571C2.39847 203.571 0 201.173 0 198.214C0 195.256 2.39847 192.857 5.35714 192.857C8.31581 192.857 10.7143 195.256 10.7143 198.214Z" fill="#F0EAFF"/>
<path d="M42.8571 198.214C42.8571 201.173 40.4587 203.571 37.5 203.571C34.5413 203.571 32.1429 201.173 32.1429 198.214C32.1429 195.256 34.5413 192.857 37.5 192.857C40.4587 192.857 42.8571 195.256 42.8571 198.214Z" fill="#F0EAFF"/>
<path d="M75 198.214C75 201.173 72.6015 203.571 69.6429 203.571C66.6842 203.571 64.2857 201.173 64.2857 198.214C64.2857 195.256 66.6842 192.857 69.6429 192.857C72.6015 192.857 75 195.256 75 198.214Z" fill="#F0EAFF"/>
<path d="M107.143 198.214C107.143 201.173 104.744 203.571 101.786 203.571C98.827 203.571 96.4286 201.173 96.4286 198.214C96.4286 195.256 98.827 192.857 101.786 192.857C104.744 192.857 107.143 195.256 107.143 198.214Z" fill="#F0EAFF"/>
<path d="M139.286 198.214C139.286 201.173 136.887 203.571 133.929 203.571C130.97 203.571 128.571 201.173 128.571 198.214C128.571 195.256 130.97 192.857 133.929 192.857C136.887 192.857 139.286 195.256 139.286 198.214Z" fill="#F0EAFF"/>
<path d="M171.429 198.214C171.429 201.173 169.03 203.571 166.071 203.571C163.113 203.571 160.714 201.173 160.714 198.214C160.714 195.256 163.113 192.857 166.071 192.857C169.03 192.857 171.429 195.256 171.429 198.214Z" fill="#F0EAFF"/>
<path d="M203.571 198.214C203.571 201.173 201.173 203.571 198.214 203.571C195.256 203.571 192.857 201.173 192.857 198.214C192.857 195.256 195.256 192.857 198.214 192.857C201.173 192.857 203.571 195.256 203.571 198.214Z" fill="#F0EAFF"/>
<path d="M235.714 198.214C235.714 201.173 233.316 203.571 230.357 203.571C227.398 203.571 225 201.173 225 198.214C225 195.256 227.398 192.857 230.357 192.857C233.316 192.857 235.714 195.256 235.714 198.214Z" fill="#F0EAFF"/>
<path d="M267.857 198.214C267.857 201.173 265.459 203.571 262.5 203.571C259.541 203.571 257.143 201.173 257.143 198.214C257.143 195.256 259.541 192.857 262.5 192.857C265.459 192.857 267.857 195.256 267.857 198.214Z" fill="#F0EAFF"/>
<path d="M300 198.214C300 201.173 297.602 203.571 294.643 203.571C291.684 203.571 289.286 201.173 289.286 198.214C289.286 195.256 291.684 192.857 294.643 192.857C297.602 192.857 300 195.256 300 198.214Z" fill="#F0EAFF"/>
<path d="M42.8571 230.357C42.8571 233.316 40.4587 235.714 37.5 235.714C34.5413 235.714 32.1429 233.316 32.1429 230.357C32.1429 227.398 34.5413 225 37.5 225C40.4587 225 42.8571 227.398 42.8571 230.357Z" fill="#F0EAFF"/>
<path d="M75 230.357C75 233.316 72.6015 235.714 69.6429 235.714C66.6842 235.714 64.2857 233.316 64.2857 230.357C64.2857 227.398 66.6842 225 69.6429 225C72.6015 225 75 227.398 75 230.357Z" fill="#F0EAFF"/>
<path d="M107.143 230.357C107.143 233.316 104.744 235.714 101.786 235.714C98.827 235.714 96.4286 233.316 96.4286 230.357C96.4286 227.398 98.827 225 101.786 225C104.744 225 107.143 227.398 107.143 230.357Z" fill="#F0EAFF"/>
<path d="M139.286 230.357C139.286 233.316 136.887 235.714 133.929 235.714C130.97 235.714 128.571 233.316 128.571 230.357C128.571 227.398 130.97 225 133.929 225C136.887 225 139.286 227.398 139.286 230.357Z" fill="#F0EAFF"/>
<path d="M171.429 230.357C171.429 233.316 169.03 235.714 166.071 235.714C163.113 235.714 160.714 233.316 160.714 230.357C160.714 227.398 163.113 225 166.071 225C169.03 225 171.429 227.398 171.429 230.357Z" fill="#F0EAFF"/>
<path d="M203.571 230.357C203.571 233.316 201.173 235.714 198.214 235.714C195.256 235.714 192.857 233.316 192.857 230.357C192.857 227.398 195.256 225 198.214 225C201.173 225 203.571 227.398 203.571 230.357Z" fill="#F0EAFF"/>
<path d="M235.714 230.357C235.714 233.316 233.316 235.714 230.357 235.714C227.398 235.714 225 233.316 225 230.357C225 227.398 227.398 225 230.357 225C233.316 225 235.714 227.398 235.714 230.357Z" fill="#F0EAFF"/>
<path d="M267.857 230.357C267.857 233.316 265.459 235.714 262.5 235.714C259.541 235.714 257.143 233.316 257.143 230.357C257.143 227.398 259.541 225 262.5 225C265.459 225 267.857 227.398 267.857 230.357Z" fill="#F0EAFF"/>
<path d="M75 262.5C75 265.459 72.6015 267.857 69.6429 267.857C66.6842 267.857 64.2857 265.459 64.2857 262.5C64.2857 259.541 66.6842 257.143 69.6429 257.143C72.6015 257.143 75 259.541 75 262.5Z" fill="#F0EAFF"/>
<path d="M107.143 262.5C107.143 265.459 104.744 267.857 101.786 267.857C98.827 267.857 96.4286 265.459 96.4286 262.5C96.4286 259.541 98.827 257.143 101.786 257.143C104.744 257.143 107.143 259.541 107.143 262.5Z" fill="#F0EAFF"/>
<path d="M139.286 262.5C139.286 265.459 136.887 267.857 133.929 267.857C130.97 267.857 128.571 265.459 128.571 262.5C128.571 259.541 130.97 257.143 133.929 257.143C136.887 257.143 139.286 259.541 139.286 262.5Z" fill="#F0EAFF"/>
<path d="M171.429 262.5C171.429 265.459 169.03 267.857 166.071 267.857C163.113 267.857 160.714 265.459 160.714 262.5C160.714 259.541 163.113 257.143 166.071 257.143C169.03 257.143 171.429 259.541 171.429 262.5Z" fill="#F0EAFF"/>
<path d="M203.571 262.5C203.571 265.459 201.173 267.857 198.214 267.857C195.256 267.857 192.857 265.459 192.857 262.5C192.857 259.541 195.256 257.143 198.214 257.143C201.173 257.143 203.571 259.541 203.571 262.5Z" fill="#F0EAFF"/>
<path d="M235.714 262.5C235.714 265.459 233.316 267.857 230.357 267.857C227.398 267.857 225 265.459 225 262.5C225 259.541 227.398 257.143 230.357 257.143C233.316 257.143 235.714 259.541 235.714 262.5Z" fill="#F0EAFF"/>
<path d="M107.143 294.643C107.143 297.602 104.744 300 101.786 300C98.827 300 96.4286 297.602 96.4286 294.643C96.4286 291.684 98.827 289.286 101.786 289.286C104.744 289.286 107.143 291.684 107.143 294.643Z" fill="#F0EAFF"/>
<path d="M139.286 294.643C139.286 297.602 136.887 300 133.929 300C130.97 300 128.571 297.602 128.571 294.643C128.571 291.684 130.97 289.286 133.929 289.286C136.887 289.286 139.286 291.684 139.286 294.643Z" fill="#F0EAFF"/>
<path d="M171.429 294.643C171.429 297.602 169.03 300 166.071 300C163.113 300 160.714 297.602 160.714 294.643C160.714 291.684 163.113 289.286 166.071 289.286C169.03 289.286 171.429 291.684 171.429 294.643Z" fill="#F0EAFF"/>
<path d="M203.571 294.643C203.571 297.602 201.173 300 198.214 300C195.256 300 192.857 297.602 192.857 294.643C192.857 291.684 195.256 289.286 198.214 289.286C201.173 289.286 203.571 291.684 203.571 294.643Z" fill="#F0EAFF"/>
</svg>
    )
}