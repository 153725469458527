import React from "react";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { Navbar } from "./components/navbar";
import { Routes, Route } from "react-router-dom";
import { Container } from "./components/container/index";
import { Helmet } from "react-helmet";
const noCard = false;

const theme = {
  text_black: "#1C1C1C",
  button_color: "#FFA412",
  text_gray: "#AAAAAA",
  shadow_purple: "#9571FB25",
  gray_one: "#E5E5E5",
  purple: "#9571FB",
  gray_two: "#8E8E8E",
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        {/* Meta Pixel Code */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '902356878285953');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=902356878285953&ev=PageView&noscript=1" />`}
        </noscript>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '464441929302975');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=464441929302975&ev=PageView&noscript=1" />`}
        </noscript>
        {/* End Meta Pixel Code */}
      </Helmet>

      <Navbar noCard={noCard} />
      <>
        <Routes>
          <Route path="/" element={<Container noCard={noCard} />} />
        </Routes>
      </>
    </ThemeProvider>
  );
}

export default App;
