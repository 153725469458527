export const DecorationIcon = () => {
    return(
        <svg width="217" height="181" viewBox="0 0 217 181" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-13.6225 -330.827C13.0587 -360.668 62.0383 -350.436 74.5407 -312.408L214.11 112.106C226.612 150.133 193.261 187.434 154.077 179.248L-283.348 87.8613C-322.531 79.6751 -338.159 32.1411 -311.478 2.30025L-13.6225 -330.827Z" fill="url(#paint0_linear_219_1927)"/>
            <defs>
                <linearGradient id="paint0_linear_219_1927" x1="265" y1="-341.5" x2="221.5" y2="358.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BDA6FF"/>
                    <stop offset="1" stopColor="white" stopOpacity="0.29"/>
                </linearGradient>
            </defs>
        </svg>
    )
}