import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { Button } from "../../block/Button";
import { routes } from "../../utils/routes";
import logo from "../../icon/logo3.png";

const NavbarContainer = styled.div`
  padding: 0 60px;
  width: 100%;
  min-width: 100vw;
  height: 57px;
  position: fixed;
  background-image: linear-gradient(180deg, #ffffff89, #ffffff11);
  top: 0;
  left: 0;
  z-index: 10;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .routes-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;

      a {
        margin-right: 32px;
        font-size: 1.125rem;
        text-decoration: none;
        color: ${(props) => props.theme.text_black};
        font-weight: 600;
        cursor: pointer;
      }
    }

    .logo {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 600px) {
    padding: 0 15px;
    a {
      display: none;
    }
  }
`;

export const Navbar = ({ noCard }) => {
  return (
    <NavbarContainer>
      <div className="container">
        <div className="logo">
          <img src={logo} width={"120px"} />
        </div>
        <div className="routes-container">
          {routes.map((route, key) => (
            <Link
              key={key}
              to={route.route}
              spy={true}
              smooth={true}
              hashSpy={true}
              duration={500}
              isDynamic={true}
              ignoreCancelEvents={false}
            >
              {" "}
              {route.naming}
            </Link>
          ))}
          {!noCard && (
            <Button onClick={() => window.location.assign("https://plataforma.clubpressto.com.ar")}>Ingresar</Button>
          )}
        </div>
      </div>
    </NavbarContainer>
  );
};
