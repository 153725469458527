import React from "react";
import styled from "styled-components";
import { Card } from "../../block/card";
import { Button } from "../../block/Button";

const RecomendationContainer = styled.div`
  min-height: 100vh;
  max-height: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  h2 {
    font-size: 30px;
    color: ${(props) => props.theme.text_black};
    margin-bottom: 10px;
    flex: 0.1;
    span {
      color: ${(props) => props.theme.purple};
    }
  }
  .card-container {
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 0.2;
    align-items: center;
  }

  .button {
    width: 300px;
    height: 41px;
    border-radius: 10px;
    border-style: none;
    font-size: 18px;
    font-weight: 700;
    color: white;
    background-color: #9571fb;
    cursor: pointer;
    padding: 10px 0;
    margin-bottom: 20px;

    &:hover {
      background-color: #8d64fd;
    }
  }
  @media (max-width: 600px) {
    max-height: none;
    padding: 0 15px;
    h2 {
      width: 80%;
      text-align: center;
    }
    .card-container {
      max-width: 100vw;
      flex-direction: column;
      padding-bottom: 80px;
    }
  }
`;

export const Recomendation = () => {
  return (
    <RecomendationContainer id="beneficios">
      <h2>
        Beneficios <span>Club Pressto</span>
      </h2>
      <button
        className="button"
        style={{ marginTop: 10 }}
        //onClick={() => (window.location.href = getWhatsAppLink())}
        onClick={() => window.location.assign("https://plataforma.clubpressto.com.ar")}
      >
        Solicitar Beneficios
      </button>
      <div className="card-container">
        <Card
          image="img/mediar.png"
          title="Asesoramiento Legal"
          text="Tendrás acceso a un abogado especializado en el tema que necesites en el estudio jurídico Mediar®."
          recomendation
        />
        <Card
          image="img/loan.png"
          title="Préstamos"
          text="Préstamos al instante, con una tasa preferencial. Sujeto a aprobación crediticia."
          recomendation
          selected
        />
        <Card
          image="img/discount.png"
          title="Descuentos Exclusivos"
          text="Cientos de descuentos exclusivos en locales adheridos a Club Pressto."
          recomendation
        />
      </div>
      <div className="card-container">
        <Card
          image="img/integral.png"
          title="Servicios Integrales"
          text="Técnicos/reparadores, limpieza, mantenimiento, plomería, electricidad, gas, cerrajería, gestoría, entre otros."
          recomendation
        />
        <Card
          image="img/online.png"
          title="Servicios Online"
          text="Terapia en línea, asistencia informática, mediphone, y más."
          recomendation
        />
        <Card
          image="img/giveaway.png"
          title="Sorteo Semanal"
          text="Todas las semanas sorteamos premios exclusivos entre los miembros de Club Pressto."
          recomendation
        />
      </div>
    </RecomendationContainer>
  );
};
