export const Decoration5 = () => {
    return(
        <svg width="246" height="182" viewBox="0 0 246 182" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4283 9.35165L11.9032 0L9.37817 9.35165L0 11.8696L9.37817 14.3875L11.9032 23.7391L14.4283 14.3875L23.8065 11.8696L14.4283 9.35165Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M46.1702 9.35165L43.6452 0L41.1201 9.35165L31.7419 11.8696L41.1201 14.3875L43.6452 23.7391L46.1702 14.3875L55.5484 11.8696L46.1702 9.35165Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M75.3871 0L77.9121 9.35165L87.2903 11.8696L77.9121 14.3875L75.3871 23.7391L72.862 14.3875L63.4839 11.8696L72.862 9.35165L75.3871 0Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M109.654 9.35165L107.129 0L104.604 9.35165L95.2258 11.8696L104.604 14.3875L107.129 23.7391L109.654 14.3875L119.032 11.8696L109.654 9.35165Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M138.871 0L141.396 9.35165L150.774 11.8696L141.396 14.3875L138.871 23.7391L136.346 14.3875L126.968 11.8696L136.346 9.35165L138.871 0Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M173.138 9.35165L170.613 0L168.088 9.35165L158.71 11.8696L168.088 14.3875L170.613 23.7391L173.138 14.3875L182.516 11.8696L173.138 9.35165Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M202.355 0L204.88 9.35165L214.258 11.8696L204.88 14.3875L202.355 23.7391L199.83 14.3875L190.452 11.8696L199.83 9.35165L202.355 0Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M236.622 9.35165L234.097 0L231.572 9.35165L222.194 11.8696L231.572 14.3875L234.097 23.7391L236.622 14.3875L246 11.8696L236.622 9.35165Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M11.9032 31.6522L14.4283 41.0038L23.8065 43.5217L14.4283 46.0397L11.9032 55.3913L9.37817 46.0397L0 43.5217L9.37817 41.0038L11.9032 31.6522Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M46.1702 41.0038L43.6452 31.6522L41.1201 41.0038L31.7419 43.5217L41.1201 46.0397L43.6452 55.3913L46.1702 46.0397L55.5484 43.5217L46.1702 41.0038Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M75.3871 31.6522L77.9121 41.0038L87.2903 43.5217L77.9121 46.0397L75.3871 55.3913L72.862 46.0397L63.4839 43.5217L72.862 41.0038L75.3871 31.6522Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M109.654 41.0038L107.129 31.6522L104.604 41.0038L95.2258 43.5217L104.604 46.0397L107.129 55.3913L109.654 46.0397L119.032 43.5217L109.654 41.0038Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M138.871 31.6522L141.396 41.0038L150.774 43.5217L141.396 46.0397L138.871 55.3913L136.346 46.0397L126.968 43.5217L136.346 41.0038L138.871 31.6522Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M173.138 41.0038L170.613 31.6522L168.088 41.0038L158.71 43.5217L168.088 46.0397L170.613 55.3913L173.138 46.0397L182.516 43.5217L173.138 41.0038Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M202.355 31.6522L204.88 41.0038L214.258 43.5217L204.88 46.0397L202.355 55.3913L199.83 46.0397L190.452 43.5217L199.83 41.0038L202.355 31.6522Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M236.622 41.0038L234.097 31.6522L231.572 41.0038L222.194 43.5217L231.572 46.0397L234.097 55.3913L236.622 46.0397L246 43.5217L236.622 41.0038Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M11.9032 63.3043L14.4283 72.656L23.8065 75.1739L14.4283 77.6918L11.9032 87.0435L9.37817 77.6918L0 75.1739L9.37817 72.656L11.9032 63.3043Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M46.1702 72.656L43.6452 63.3043L41.1201 72.656L31.7419 75.1739L41.1201 77.6918L43.6452 87.0435L46.1702 77.6918L55.5484 75.1739L46.1702 72.656Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M75.3871 63.3043L77.9121 72.656L87.2903 75.1739L77.9121 77.6918L75.3871 87.0435L72.862 77.6918L63.4839 75.1739L72.862 72.656L75.3871 63.3043Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M109.654 72.656L107.129 63.3043L104.604 72.656L95.2258 75.1739L104.604 77.6918L107.129 87.0435L109.654 77.6918L119.032 75.1739L109.654 72.656Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M138.871 63.3043L141.396 72.656L150.774 75.1739L141.396 77.6918L138.871 87.0435L136.346 77.6918L126.968 75.1739L136.346 72.656L138.871 63.3043Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M173.138 72.656L170.613 63.3043L168.088 72.656L158.71 75.1739L168.088 77.6918L170.613 87.0435L173.138 77.6918L182.516 75.1739L173.138 72.656Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M202.355 63.3043L204.88 72.656L214.258 75.1739L204.88 77.6918L202.355 87.0435L199.83 77.6918L190.452 75.1739L199.83 72.656L202.355 63.3043Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M236.622 72.656L234.097 63.3043L231.572 72.656L222.194 75.1739L231.572 77.6918L234.097 87.0435L236.622 77.6918L246 75.1739L236.622 72.656Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M11.9032 94.9565L14.4283 104.308L23.8065 106.826L14.4283 109.344L11.9032 118.696L9.37817 109.344L0 106.826L9.37817 104.308L11.9032 94.9565Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M46.1702 104.308L43.6452 94.9565L41.1201 104.308L31.7419 106.826L41.1201 109.344L43.6452 118.696L46.1702 109.344L55.5484 106.826L46.1702 104.308Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M75.3871 94.9565L77.9121 104.308L87.2903 106.826L77.9121 109.344L75.3871 118.696L72.862 109.344L63.4839 106.826L72.862 104.308L75.3871 94.9565Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M109.654 104.308L107.129 94.9565L104.604 104.308L95.2258 106.826L104.604 109.344L107.129 118.696L109.654 109.344L119.032 106.826L109.654 104.308Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M138.871 94.9565L141.396 104.308L150.774 106.826L141.396 109.344L138.871 118.696L136.346 109.344L126.968 106.826L136.346 104.308L138.871 94.9565Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M173.138 104.308L170.613 94.9565L168.088 104.308L158.71 106.826L168.088 109.344L170.613 118.696L173.138 109.344L182.516 106.826L173.138 104.308Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M202.355 94.9565L204.88 104.308L214.258 106.826L204.88 109.344L202.355 118.696L199.83 109.344L190.452 106.826L199.83 104.308L202.355 94.9565Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M236.622 104.308L234.097 94.9565L231.572 104.308L222.194 106.826L231.572 109.344L234.097 118.696L236.622 109.344L246 106.826L236.622 104.308Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M11.9032 126.609L14.4283 135.96L23.8065 138.478L14.4283 140.996L11.9032 150.348L9.37817 140.996L0 138.478L9.37817 135.96L11.9032 126.609Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M46.1702 135.96L43.6452 126.609L41.1201 135.96L31.7419 138.478L41.1201 140.996L43.6452 150.348L46.1702 140.996L55.5484 138.478L46.1702 135.96Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M75.3871 126.609L77.9121 135.96L87.2903 138.478L77.9121 140.996L75.3871 150.348L72.862 140.996L63.4839 138.478L72.862 135.96L75.3871 126.609Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M109.654 135.96L107.129 126.609L104.604 135.96L95.2258 138.478L104.604 140.996L107.129 150.348L109.654 140.996L119.032 138.478L109.654 135.96Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M138.871 126.609L141.396 135.96L150.774 138.478L141.396 140.996L138.871 150.348L136.346 140.996L126.968 138.478L136.346 135.96L138.871 126.609Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M173.138 135.96L170.613 126.609L168.088 135.96L158.71 138.478L168.088 140.996L170.613 150.348L173.138 140.996L182.516 138.478L173.138 135.96Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M202.355 126.609L204.88 135.96L214.258 138.478L204.88 140.996L202.355 150.348L199.83 140.996L190.452 138.478L199.83 135.96L202.355 126.609Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M236.622 135.96L234.097 126.609L231.572 135.96L222.194 138.478L231.572 140.996L234.097 150.348L236.622 140.996L246 138.478L236.622 135.96Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M11.9032 158.261L14.4283 167.613L23.8065 170.13L14.4283 172.648L11.9032 182L9.37817 172.648L0 170.13L9.37817 167.613L11.9032 158.261Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M46.1702 167.613L43.6452 158.261L41.1201 167.613L31.7419 170.13L41.1201 172.648L43.6452 182L46.1702 172.648L55.5484 170.13L46.1702 167.613Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M75.3871 158.261L77.9121 167.613L87.2903 170.13L77.9121 172.648L75.3871 182L72.862 172.648L63.4839 170.13L72.862 167.613L75.3871 158.261Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M109.654 167.613L107.129 158.261L104.604 167.613L95.2258 170.13L104.604 172.648L107.129 182L109.654 172.648L119.032 170.13L109.654 167.613Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M138.871 158.261L141.396 167.613L150.774 170.13L141.396 172.648L138.871 182L136.346 172.648L126.968 170.13L136.346 167.613L138.871 158.261Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M173.138 167.613L170.613 158.261L168.088 167.613L158.71 170.13L168.088 172.648L170.613 182L173.138 172.648L182.516 170.13L173.138 167.613Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M202.355 158.261L204.88 167.613L214.258 170.13L204.88 172.648L202.355 182L199.83 172.648L190.452 170.13L199.83 167.613L202.355 158.261Z" fill="#F7EBFD" fillOpacity="0.45"/>
<path d="M236.622 167.613L234.097 158.261L231.572 167.613L222.194 170.13L231.572 172.648L234.097 182L236.622 172.648L246 170.13L236.622 167.613Z" fill="#F7EBFD" fillOpacity="0.45"/>
</svg>

    )
}