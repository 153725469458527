import { Button } from "@mui/material";
import React from "react";

function AppButton({ children, onClick, selected }) {
  const styles = {
    button: {
      background: selected ? "#9571FB" : "#E0E0E0",
      borderRadius: "8px",
      color: selected ? "white" : "#A4A4A4",
      fontSize: 16,
      fontWeight: 500,
      boxShadow: "none",
      margin: "4px",
      "&:hover": {
        background: "#9571FB",
        color: "white",
        opacity: 0.8,
      },
    },
  };

  return (
    <Button onClick={onClick} sx={[styles.button, {}]} variant="contained">
      {children}
    </Button>
  );
}

export default AppButton;
