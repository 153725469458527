import React, { useState, useRef, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PlusIcon } from "./../../icon/PlusIcon";

const FAQContainer = styled.div`
  padding: 0 100px 40px 100px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: 700 !important;
  }
  h2 {
    font-size: 40px;
    color: ${(props) => props.theme.text_black};
    margin-bottom: 50px;
    flex: 0.1;
    span {
      color: ${(props) => props.theme.purple};
    }
  }
  .MuiAccordionSummary-gutters {
    flex-direction: row-reverse;
  }
  .MuiPaper-root {
    width: 100%;
    border-radius: 10px;
    padding: 13px 18px 15px 18px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(196, 196, 196, 0.314);
    margin: 25px 0;
    box-shadow: 0px 0px 0px 0px transparent;
    transition: all 0.2s ease-out;
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 0px 24px 1px #bfbfbf42;
    }
    &::before {
      background-color: transparent;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 20px;
  }
  .MuiTypography-root {
    font-size: 20px;
    font-weight: 500;
  }
  .MuiTypography-h4 {
    font-size: 15px;
    color: ${(props) => props.theme.gray_two};
  }
  @media (max-width: 600px) {
    padding: 0 15px 40px 15px;
    min-height: 100vh;
    height: 100%;
    h2 {
      font-size: 30px;
    }
    .MuiPaper-root {
      margin: 15px 0;
    }
  }
`;

const textanimation = keyframes`
 0% { transform: translateY(80px); opacity: 0; }
 100% { transform: translateY(0); opacity: 1; }
`;

const AccordionAnimated = styled(Accordion)`
  opacity: 0;
  animation: ${(props) =>
    props.first
      ? css`
          ${textanimation} 2s ease-out ${props.delay}s 1 normal forwards
        `
      : "red"};
`;

export const FAQ = ({ interest, noCard }) => {
  const referencia = useRef(null);
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(false);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  };

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (referencia.current) observer.observe(referencia.current);

    return () => {
      if (referencia.current) observer.unobserve(referencia.current);
    };
  }, [referencia, options]);

  useEffect(() => {
    visible && setFirst(true);
  }, [visible]);

  const items = [
    {
      question: "¿Cómo hago para pedir un préstamo?",
      answer:
        "En el cotizador de arriba, seleccionas el monto a solicitar, y la cantidad de cuotas a pagar. Al presionar en el botón SOLICITAR, " +
        (noCard
          ? " te pondrás en contacto con nosotros a través de WhatsApp, donde solicitaremos tus datos."
          : "te redirigirá a la plataforma de clientes donde te registrarás. Una vez registrado, te adherís al servicio Club Pressto, y solicitás el préstamo. (Sujeto a aprobación crediticia)"),
    },
    {
      question: "¿Cuáles son los requisitos para pedir un préstamo?",
      answer:
        "Necesitás ser mayor de 18 años, DNI argentino, " +
        (noCard ? "recibo de sueldo o ser monotributista, " : "una tarjeta de crédito o débito,") +
        " una cuenta bancaria argentina, y adherirte al servicio Club Pressto. Sujeto a aprobación crediticia.",
    },
    {
      question: "¿Cómo recibo el dinero del préstamo?",
      answer: "El dinero te lo enviamos de inmediato por transferencia bancaria.",
    },
    {
      question: "¿Debo de ir a alguna oficina de Pressto?",
      answer: "No, Pressto es un servicio 100% digital.",
    },
    {
      question: "¿Cuánto dinero y en cuántas cuotas puedo solicitar?",
      answer:
        "Podés solicitar hasta $" +
        interest[0]?.maxAllowedAmount +
        " en " +
        (interest
          .map((i) => {
            return i.installments;
          })
          .slice(0, -1)
          .join(", ") +
          " y " +
          interest.slice(-1)[0]?.installments) +
        " cuotas. Si necesitás ampliar el límite, contactate con nosotros.",
    },
    {
      question: "¿Cómo solicito la baja del servicio?",
      answer:
        "Para solicitar la baja del servicio Club Pressto, enviá un mail a baja@clubpressto.com.ar con tu nombre, apellido y DNI.",
    },
  ];

  return (
    <FAQContainer ref={referencia} id="faq">
      <h2>
        <span>Preguntas</span> frecuentes
      </h2>
      {items.map((item, index) => (
        <AccordionAnimated key={index} first={first} delay={index / 5}>
          <AccordionSummary
            expandIcon={<PlusIcon className={"icon"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h1"> {item.question} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h4"> {item.answer} </Typography>
          </AccordionDetails>
        </AccordionAnimated>
      ))}
    </FAQContainer>
  );
};
