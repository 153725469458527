import React from 'react';
import styled from "styled-components";

const ButtonPressto = styled.button`
    padding: 4px 13px 6px 13px;
    border: 2px solid ${ ( props ) => props.theme.button_color  } ;
    color: ${ ( props ) => props.theme.button_color };
    background-color: transparent;
    font-size: 1.125rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover{
        transition: all 0.2s ease-out;
        background-color: ${ ( props ) => props.theme.button_color };
        color: white;
    }
`

export const Button = ( { children, onClick } ) => {
    return(
        <ButtonPressto onClick={onClick ? onClick : undefined}>
            { children }
        </ButtonPressto>
    )
}