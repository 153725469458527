const getInterest = (onSuccess, onFailure) => {
  fetch("https://api.clubpressto.com.ar/checkout/interest").then((response) => {
    if (response.ok) response.json().then(onSuccess);
    else onFailure();
  });
};

const getInterestByBIN = (bin, onSuccess, onFailure) => {
  fetch("https://api.clubpressto.com.ar/checkout/interest?bin=" + bin).then((response) => {
    if (response.ok) response.json().then(onSuccess);
    else onFailure();
  });
};

const getInterestNoCard = (onSuccess, onFailure) => {
  fetch("https://api.clubpressto.com.ar/checkout/interest/nocard").then((response) => {
    if (response.ok) response.json().then(onSuccess);
    else onFailure();
  });
};

const api = {
  getInterest,
  getInterestByBIN,
  getInterestNoCard,
};

export default api;
