import React, { useState, useRef, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { Card } from "../../block/card";
import { HappyPersonIcon } from "./../../icon/HappyPersonIcon";
import { CardIcon } from "./../../icon/CardIcon";
import { CashIcon } from "./../../icon/CashIcon";
import { Decoration3 } from "../../icon/Decoration3";

const textanimation = keyframes`
 0% { transform: translateY(80px); opacity: 0; }
 100% { transform: translateY(0); opacity: 1; }
`;

const RequirementContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  max-height: 768px;
  padding: 50px 100px 0 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 40px;
    color: ${(props) => props.theme.text_black};
    margin-bottom: 90px;
    flex: 0.1;
    span {
      color: ${(props) => props.theme.purple};
    }
  }
  .card-container {
    flex: 0.9;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 2;
    max-width: 1150px;
    justify-content: space-around;
    width: 100%;

    .decoration-container {
      position: absolute;
      bottom: 0;
      right: 35%;
      z-index: -1;
    }
  }
  @media (max-width: 600px) {
    max-height: none;
    height: 100%;
    padding: 50px 15px 0;
    h2 {
      font-size: 30px;
    }
    .card-container {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Requirement = ({ noCard }) => {
  const referencia = useRef(null);
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(false);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  };

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (referencia.current) observer.observe(referencia.current);

    return () => {
      if (referencia.current) observer.unobserve(referencia.current);
    };
  }, [referencia, options]);

  useEffect(() => {
    visible && setFirst(true);
  }, [visible]);

  return (
    <RequirementContainer first={first} ref={referencia} id="requisitos">
      <h2>
        ¿Qué <span>necesitás</span>?
      </h2>
      <div className="card-container">
        <Card
          animation={textanimation}
          haveAnimation={first}
          delay={0}
          icon={<HappyPersonIcon />}
          color={"#DDE5FC"}
          title={"Ser mayor de 18 años y tener DNI argentino"}
          text={
            "Deberás tener al menos 18 años de edad, y DNI argentino. Además, necesitarás una cuenta de correo electrónico."
          }
        />
        {
          /*!noCard && */ <Card
            animation={textanimation}
            haveAnimation={first}
            delay={1.5}
            icon={<CardIcon />}
            selected
            color={"#DCFCEF"}
            title={!noCard ? "Una tarjeta de crédito o débito" : "Recibo de sueldo o monotributo"}
            text={
              !noCard
                ? "Para poder solicitar el préstamo, necesitarás tener tu tarjeta de crédito o débito a mano."
                : "Para poder solicitar el préstamo, necesitarás brindar tu recibo de sueldo, o certificado de monotributo."
            }
          />
        }

        <Card
          animation={textanimation}
          haveAnimation={first}
          delay={0.7}
          icon={<CashIcon />}
          color={"#F7EBFD"}
          title={"Tener CBU y estar adherido a Club Pressto"}
          text={"Será necesaria para depositar el dinero solicitado."}
        />
        <div className="decoration-container">
          <Decoration3 />
        </div>
      </div>
    </RequirementContainer>
  );
};
